// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_bIYdRT4gZ4lr7uwcO0", // sketchg
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/7sI14X6Rod7M5EIbJW", // sketchg

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Sketch Gen AI 🎨",
    HOME_PAGE_TITLE: "Home - Sketch Gen AI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Sketch Gen AI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Sketch Gen AI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to generate images using a sketch in an image, choose an image, enter a prompt and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "sketch_gen_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/sketch-gen-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;